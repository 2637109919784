var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.productData.banner),expression:"productData.banner"}],key:_vm.productData.banner,attrs:{"alt":"top"}})]),_c('div',{staticClass:"top-center"},[_c('p',[_vm._v(_vm._s(_vm.productData.name))]),_c('span',[_vm._v(_vm._s(_vm.productData.sketch))]),_c('el-button',{staticClass:"banner-btn",attrs:{"plain":""},on:{"click":() => {
						this.$router.push('/formSub')
					}}},[_vm._v("立即咨询")])],1)]),_c('div',{staticClass:"container"},[(!_vm.isMobileEnd)?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.containerBgUrl),expression:"containerBgUrl",arg:"background-image"}],staticClass:"containerBg"}):_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.containerBgUrl),expression:"containerBgUrl",arg:"background-image"}],staticClass:"containerBg",style:({
				top:
					_vm.productData.capabilities.length == 9
						? '700px'
						: '600px',
			})}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"product-function"},[_vm._m(0),_c('div',{staticClass:"product-function-center"},[_c('product-features',{attrs:{"overview":_vm.productData.overview,"productName":_vm.productData.name,"outlined":_vm.productData.outlined,"capabilities":_vm.productData.capabilities}})],1)]),_c('div',{staticClass:"product-scenario"},[_vm._m(1),_c('div',{staticClass:"product-scenario-center"},[_c('app-scenario',{attrs:{"scenesList":_vm.productData.scenes}})],1)]),_c('div',{staticClass:"product-feature"},[_vm._m(2),_c('div',{staticClass:"product-feature-center"},_vm._l((_vm.productData.features),function(i,index){return _c('div',{key:index,staticClass:"product-feature-center-item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(i.featureImg),expression:"i.featureImg"}],key:i.featureImg,attrs:{"alt":"方案特点"}}),_c('h5',[_vm._v(_vm._s(i.featureName))]),_c('p',[_vm._v(_vm._s(i.featureIntroduction))])])}),0)])])]),_c('div',{staticClass:"left"}),_c('div',{staticClass:"right"})]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.openBgUrl),expression:"openBgUrl",arg:"background-image"}],staticClass:"open-api"},[_c('div',{staticClass:"open-api-center"},[_c('div',{staticClass:"open-api-center-A"},[_vm._v("快速体验慧脑智能开放平台")]),_c('p',{staticClass:"open-api-center-B"},[_vm._v(" 让您快速了解并体验更多我们产品相关的能力 ")]),_c('div',{staticClass:"open-api-center-C"},[_c('el-input',{staticClass:"open-api-center-C-input",attrs:{"placeholder":"您的手机号码","maxlength":"11"},on:{"input":_vm.handleInput},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_c('el-button',{attrs:{"slot":"append"},on:{"click":_vm.handleOpenAi},slot:"append"},[_vm._v(" 立即体验")])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("产品功能")]),_c('span',{staticClass:"title-desc"},[_vm._v("功能点完善并深入，满足客户相应需求")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("应用场景")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("产品特点")]),_c('span',{staticClass:"title-desc"},[_vm._v("精准提炼本产品，高效了解其优势")])])
}]

export { render, staticRenderFns }