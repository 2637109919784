import axios from 'axios'
import router from '../router'
import store from '../store'
// import { baseUrl } from '../../public/url';
const baseUrl = window.baseUrl
// var baseUrl = queryUrl()?'http://192.168.0.81:8081/':'./';
// function queryUrl() {
//     return window.location.href.indexOf('192.168.0.171') > -1
// }

export default function request(config) {
	const instance = axios.create({
		baseURL: baseUrl,
	})

	instance.interceptors.request.use(
		(config) => {
			let token = store.state.token
			if (token) {
				config.headers.Authorization = token
			}
			return config
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	instance.interceptors.response.use(
		(res) => {
			store.state.loading = false

			if (
				res.data.code == '401' ||
				res.data.code == '9002' ||
				res.data.code == '-7'
			) {
				store.commit('loginChanged', {
					userType: 1,
					loginName: '',
					userName: '',
					userId: '',
					token: '',
				})
				router.replace('/login')
				return {
					data: {
						code: 401,
						msg: '登录超时',
					},
				}
			}
			return Promise.resolve(res)
		},
		(error) => {
			store.state.loading = false
			return Promise.reject(error)
		}
	)

	return instance(config)
}
