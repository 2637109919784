<!-- 产品中心-产品功能 -->
<template>
	<div class="ProductFeatures-warp" v-if="!isMobileEnd">
		<div class="ProductFeatures-warp-left">
			<div class="ProductFeatures-warp-left-top">
				<div class="ProductFeatures-warp-left-top-productName">
					{{ productName }}
				</div>
				<div class="ProductFeatures-warp-left-top-overview">
					{{ overview }}
				</div>
				<div class="ProductFeatures-warp-left-top-outlined">
					<div v-for="(e, i) in outlined" :key="i">
						<i class="iconfont icon-wancheng"></i
						><span>{{ e.briefDesc }}</span>
					</div>
				</div>
				<el-button
					class="ProductFeatures-warp-left-top-btn"
					plain
					@click="
						() => {
							this.$router.push('/formSub')
						}
					"
					>立即咨询</el-button
				>
			</div>
			<div class="ProductFeatures-warp-left-bottom">
				<img
					v-lazy="productFeaturesUrl"
					:key="productFeaturesUrl"
					alt="产品功能"
				/>
			</div>
		</div>
		<div class="ProductFeatures-warp-right">
			<div
				class="ProductFeatures-warp-right-item"
				v-for="(item, index) in capabilities"
				:key="index"
				:style="{
					height: capabilities.length == 9 ? '1.16rem' : '2.22rem',
				}"
				@mouseleave="change(`img${index + 1}`, item.capacityImg)"
				@mouseenter="
					change(`img${index + 1}`, item.capacitySelectedImg)
				"
			>
				<div class="item-hover-img">
					<img
						v-lazy="featureHoverUrl"
						:key="featureHoverUrl"
						alt="hoverBg"
					/>
				</div>
				<div class="ProductFeatures-warp-right-item-header">
					<div class="ProductFeatures-warp-right-item-header-img">
						<img
							:id="`img${index + 1}`"
							:src="item.capacityImg"
							alt="产品功能图标"
						/>
					</div>
					<div class="ProductFeatures-warp-right-item-header-name">
						{{ item.capacityName }}
					</div>
				</div>
				<div class="ProductFeatures-warp-right-item-text">
					{{ item.capacityIntroduction }}
				</div>
			</div>
		</div>
	</div>
	<div class="ProductFeatures768-warp" v-else>
		<div
			class="ProductFeatures768-warp-item"
			v-for="(item, index) in capabilities"
			:key="index"
			@mouseleave="change(`img${index + 1}`, item.capacityImg)"
			@mouseenter="change(`img${index + 1}`, item.capacitySelectedImg)"
		>
			<div class="ProductFeatures768-warp-item-hover-img">
				<img
					v-lazy="featureHoverUrl"
					:key="featureHoverUrl"
					alt="hoverBg"
				/>
			</div>
			<div class="ProductFeatures768-warp-item-img">
				<img
					:id="`img${index + 1}`"
					:src="item.capacityImg"
					alt="产品功能图标"
				/>
			</div>
			<div class="ProductFeatures768-warp-item-name">
				{{ item.capacityName }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProductFeatures',
	data() {
		return {
			productFeaturesUrl: require('@/assets/img/productFeaturesBg.png'),
			featureHoverUrl: require('@/assets/img/featureHover.png'),
		}
	},
	props: {
		overview: String,
		productName: String,
		outlined: Array,
		capabilities: Array,
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		change(imgId, imgUrl) {
			let imgDom = document.getElementById(imgId)
			imgDom.src = imgUrl
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.ProductFeatures-warp {
	width: 100%;
	height: 6.2rem;
	display: flex;
	justify-content: space-between;
	&-left {
		width: 3.38rem;
		height: 100%;
		background: linear-gradient(134deg, #4497f6 0%, #1254eb 100%);
		// margin-right: 0.16rem;
		position: relative;
		&-top {
			width: calc(100% - 0.7rem);
			height: calc(100% - 2.55rem - 0.32rem);
			padding: 0.32rem 0.35rem 0;
			&-productName {
				width: 100%;
				height: 0.35rem;
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 500;
				color: #ffffff;
				line-height: 0.35rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&-overview {
				width: 100%;
				//(0.32rem+ 0.35rem) + (0.2 + 0.27) +(0.2*3 + 0.18*2) + (0.4 + 0.3)
				height: calc(100% - 2.72rem);
				font-size: 0.14rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #ffffff;
				line-height: 0.2rem;
				margin: 0.2rem 0 0.2rem 0;
				overflow: hidden;
				text-overflow: ellipsis; //超出部分显示省略号
				-webkit-line-clamp: 3;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
			&-outlined {
				div {
					height: 0.2rem;
					font-size: 0.14rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #ffffff;
					line-height: 0.2rem;
					i {
						display: inline-block;
					}
					span {
						margin-left: 0.07rem;
					}
					&:nth-child(2) {
						margin: 0.18rem 0;
					}
				}
			}
			&-btn {
				width: 1.38rem;
				height: 0.4rem;
				background-color: #ffffff;
				color: #266ef0;
				font-size: 0.14rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				line-height: 0.2rem;
				letter-spacing: 1px;
				border-radius: 672px;
				margin-top: 0.3rem;
				&:hover {
					border: 1px solid transparent;
					background-color: #e8f5ff;
				}
			}
		}
		&-bottom {
			width: 100%;
			height: 2.55rem;
			position: absolute;
			bottom: 0;
		}
	}
	&-right {
		width: calc(100% - 3.54rem);
		height: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 0.16rem 0.16rem;
		grid-auto-flow: row;
		justify-content: space-around;
		&-item {
			width: 2.5rem;
			height: 2.22rem;
			background-color: #f8f9fb;
			padding: 0.4rem 0.23rem;
			position: relative;
			border-radius: 0.04rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			&-header {
				display: flex;
				align-items: center;
				margin-bottom: 0.24rem;
				&-img {
					width: 0.4rem;
					height: 0.4rem;
					border-radius: 50%;
					display: inline-block;
				}
				&-name {
					display: inline-block;
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 500;
					color: #525c6a;
					line-height: 0.29rem;
					margin-left: 0.12rem;
				}
			}
			&-text {
				font-size: 0.18rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #717e92;
				line-height: 0.26rem;
			}
			.item-hover-img {
				width: 0.7rem;
				height: 0.6rem;
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
			}
			&:hover {
				background: linear-gradient(134deg, #226aee 0%, #4194f5 100%);
				color: #ffffff;
				.ProductFeatures-warp-right-item-header-name,
				.ProductFeatures-warp-right-item-text {
					color: #ffffff;
				}
				.item-hover-img {
					opacity: 0.55;
				}
			}
		}
	}
}
.ProductFeatures768-warp {
	width: 100%;
	min-height: 200px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 8px;
	grid-auto-flow: row;
	justify-content: space-around;
	&-item {
		width: 109px;
		height: 96px;
		background-color: #f8f9fb;
		border-radius: 4px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&-img {
			width: 40px;
			height: 40px;
		}
		&-name {
			font-size: 10px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 500;
			color: #525c6a;
			line-height: 16px;
			margin-top: 8px;
		}
		&-hover-img {
			width: 0.7rem;
			height: 0.6rem;
			position: absolute;
			top: 0;
			right: 0;
			opacity: 0;
		}
		&:hover {
			background: linear-gradient(134deg, #226aee 0%, #4194f5 100%);
			color: #ffffff;
			.ProductFeatures768-warp-item-name {
				color: #ffffff;
			}
			.item-hover-img {
				opacity: 0.55;
			}
		}
	}
}
</style>
